var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipHtml(_vm.character)),expression:"tooltipHtml(character)"}],ref:"el",staticClass:"character-art"},[(!_vm.portrait)?_c('div',{staticClass:"trait"},[_c('span',{class:_vm.characterTrait.toLowerCase() + '-icon circle-element'}),(!_vm.portrait)?_c('div',{staticClass:"black-outline"},[_c('div',[_vm._v(" ID "),_c('span',{staticClass:"white"},[_vm._v(_vm._s(_vm.character.id))])]),(_vm.isMarket)?_c('div',[_vm._v(" Lv."),_c('span',{},[_vm._v(_vm._s(_vm.character.level + 1))])]):_vm._e()]):_vm._e()]):_vm._e(),_c('div',{staticClass:"placeholder d-flex align-items-start justify-content-center ",class:_vm.characterTrait.toLowerCase() + '-bg'},[_c('div',{class:{
        'w-100': _vm.portrait,
        'h-100': !_vm.isMarket,
        'h-100': _vm.isMarket,
      },style:({
        'background-image': 'url(' + _vm.getCharacterArt(_vm.character) + ')',
      })})]),(!_vm.allLoaded)?_c('div',{staticClass:"loading-container"},[_c('i',{staticClass:"fas fa-spinner fa-spin"})]):_vm._e(),_c('div',{class:{ 'market-bot': !_vm.portrait }},[_c('div',{staticClass:"name-lvl-container"},[(!_vm.portrait)?_c('div',{staticClass:"name black-outline",attrs:{"title":_vm.getCleanCharacterName(_vm.character.id)}},[_vm._v(" "+_vm._s(_vm.getCleanCharacterName(_vm.character.id))+" ")]):_vm._e(),(!_vm.portrait && !_vm.isMarket)?_c('div',{staticClass:"lv"},[_vm._v(" Lv."),_c('span',{},[_vm._v(_vm._s(_vm.character.level + 1))])]):_vm._e()]),_c('div',{staticClass:"xp-wrap",style:(_vm.isMarket&&'margin-top: 25px')},[(!_vm.portrait)?_c('div',{staticClass:"xp"},[_c('b-progress',{directives:[{name:"tooltip",rawName:"v-tooltip.bottom",value:(
            ("Claimable XP " + (this.getCharacterUnclaimedXp(_vm.character.id)))
          ),expression:"\n            `Claimable XP ${this.getCharacterUnclaimedXp(character.id)}`\n          ",modifiers:{"bottom":true}}],attrs:{"max":_vm.RequiredXp(_vm.character.level),"variant":"success"}},[_c('strong',{staticClass:"xp-text"},[_vm._v(_vm._s(_vm.character.xp || 0)+" / "+_vm._s(_vm.RequiredXp(_vm.character.level))+" XP")]),_c('b-progress-bar',{attrs:{"value":_vm.character.xp || 0}})],1)],1):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }