









































































































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import Events from '../../events';
import {Nft as nftItem} from '../../interfaces/Nft';
import { SkillShopListing } from '../../interfaces/SkillShopListing';
import NftIcon from '../NftIcon.vue';
import { Nft } from '@/interfaces/Nft';
import Vue from 'vue';
import { Accessors, PropType } from 'vue/types/options';
import { IState } from '@/interfaces';

const sorts = [
  { name: 'Any', dir: '' },
  { name: 'Price: Low -> High', dir: 1 },
  { name: 'Price: High -> Low', dir: -1 },
];

interface Data {
  typeFilter: string;
  starFilter: string;
  elementFilter: string;
  favorites: Record<string, Record<number, boolean>>;
  priceSort: string;
  showFavoriteNfts: boolean;
  checkBuy: string;
}

export interface NftIdType {
  id: number | string;
  type: string;
}

type StoreMappedState = Pick<IState, 'ownedShieldIds'>;

interface StoreMappedGetters {
  nftsWithIdType(nftIdType: NftIdType[]): Nft[];
  shieldsWithIds(ids: string[]): Nft[];
}

interface StoreMappedActions {
  purchaseShield(): Promise<void>;
  fetchShields(shieldIds: (string | number)[]): Promise<void>;
  purchaseRenameTag(): Promise<void>;
  purchaseRenameTagDeal(): Promise<void>;
  purchaseWeaponRenameTag(): Promise<void>;
  purchaseWeaponRenameTagDeal(): Promise<void>;
  purchaseCharacterFireTraitChange(): Promise<void>;
  purchaseCharacterEarthTraitChange(): Promise<void>;
  purchaseCharacterWaterTraitChange(): Promise<void>;
  purchaseCharacterLightningTraitChange(): Promise<void>;
  purchaseCommonSecretBox(): Promise<void>;
  purchaseRareSecretBox(): Promise<void>;
}

export default Vue.extend({
  model: {
    prop: 'highlight',
    event: 'choose-nft',
  },
  props: {
    highlight: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x;
        return true;
      },
      default: null,
    },
    showGivenNftIdTypes: {
      type: Boolean,
      default: false,
    },
    nftIdTypes: {
      type: Array as PropType<NftIdType[]>,
      default() {
        return [];
      },
    },
    isShop: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    isMarket: {
      type: Boolean,
      default: false,
    },
    ignore: {
      // this forces Typescript to consider a prop a certain type
      // without us specifying a "type" property;
      // Vue's "type" property is not as flexible as we need it here
      validator(x: string | number | null) {
        void x;
        return true;
      },
      default: null,
    },
    showLimit: {
      type: Number,
      default: 0,
    },
    showFavoriteToggle: {
      type: Boolean,
      default: true,
    },
    showFavoriteWeaponsDefVal: {
      type: Boolean,
      default: true,
    },
    canFavorite: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      typeFilter: '',
      starFilter: '',
      elementFilter: '',
      favorites: {},
      priceSort: '',
      sorts,
      showFavoriteNfts: true,
      checkBuy: "",
    } as Data;
  },

  components: {
    NftIcon
  },

  computed: {
    ...(mapState(['ownedShieldIds']) as Accessors<StoreMappedState>),
    ...(mapGetters(['shieldsWithIds','nftsWithIdType']) as Accessors<StoreMappedGetters>),

    nftsToDisplay(): NftIdType[] {
      if (this.showGivenNftIdTypes) {
        return this.nftIdTypes;
      }

      const nfts: NftIdType[] = [];
      // push different kinds of nfts to nfts array here
      this.ownedShieldIds?.forEach(id => { nfts.push({ id, type: 'shield' }); });

      return nfts;
    },

    displayNfts(): Nft[] {
      if(this.isMarket && this.showGivenNftIdTypes) {
        const type = this.nftIdTypes && this.nftIdTypes[0]?.type;
        switch(type) {
        case('shield'):
          return this.shieldsWithIds(this.nftsToDisplay.map(x => x.id.toString())).filter(Boolean);
        default:
          return [];
        }
      }

      return this.nftsWithIdType(this.nftsToDisplay).filter(Boolean);
    },

    nonIgnoredNfts(): Nft[] {
      let items: Nft[] = [];
      this.displayNfts.forEach((x) => items.push(x));

      const allIgnore: NftIdType[] = [];
      if (!this.showFavoriteNfts) {
        for (const type in Object.keys(this.favorites)) {
          for(const id in Object.keys(this.favorites[type])) {
            allIgnore.push({ type, id });
          }
        }
      }
      items = items.filter((x) => allIgnore.findIndex((y) => y.id === x.id && y.type === x.type) < 0);

      if(this.typeFilter) {
        items = items.filter((x) => x.type?.localeCompare(this.typeFilter, undefined, { sensitivity: 'base' } ) === 0);
      }

      if (this.starFilter) {
        items = items.filter((x) => x.stars === +this.starFilter - 1);
      }

      if (this.elementFilter) {
        items = items.filter((x) => x.element?.includes(this.elementFilter));
      }

      if (this.showLimit > 0 && items.length > this.showLimit) {
        items = items.slice(0, this.showLimit);
      }

      const favoriteNfts: Nft[] = [];
      for (const key in this.favorites) {
        const i = items.findIndex((y) => y?.id === +key);
        if (i !== -1) {
          favoriteNfts.push(items[i]);
          items.splice(i, 1);
        }
      }

      return favoriteNfts.concat(items);
    }
  },

  watch: {
    async nftsToDisplay(newNftsToDisplay: NftIdType[]) {
      const shieldIds: string[] = [];
      newNftsToDisplay.forEach(nft => {
        switch(nft.type) {
        case('shield'):
          shieldIds.push(nft.id.toString());
        }
      });

      if(shieldIds.length > 0) {
        await this.fetchShields(shieldIds);
      }
    },
  },

  methods: {
    ...(mapActions(['purchaseShield', 'fetchShields', 'purchaseRenameTag', 'purchaseWeaponRenameTag',
      'purchaseRenameTagDeal', 'purchaseWeaponRenameTagDeal',
      'purchaseCharacterFireTraitChange', 'purchaseCharacterEarthTraitChange',
      'purchaseCharacterWaterTraitChange', 'purchaseCharacterLightningTraitChange',
      'purchaseCommonSecretBox', 'purchaseRareSecretBox'
    ]) as StoreMappedActions),
    ...mapMutations(['setCurrentNft']),

    async onShieldBuy() {
      await this.purchaseShield();
    },

    saveFilters() {
      if(this.isMarket) {
        sessionStorage.setItem('market-nft-typefilter', this.typeFilter);
        sessionStorage.setItem('market-nft-starfilter', this.starFilter);
        sessionStorage.setItem('market-nft-elementfilter', this.elementFilter);
        sessionStorage.setItem('market-nft-price-order', this.priceSort);
      } else {
        sessionStorage.setItem('nft-typefilter', this.typeFilter);
        sessionStorage.setItem('nft-starfilter', this.starFilter);
        sessionStorage.setItem('nft-elementfilter', this.elementFilter);
      }
      this.$emit('nft-filters-changed');
    },

    clearFilters() {
      if(this.isMarket) {
        sessionStorage.removeItem('market-nft-typefilter');
        sessionStorage.removeItem('market-nft-starfilter');
        sessionStorage.removeItem('market-nft-elementfilter');
        sessionStorage.removeItem('market-nft-price-order');
      } else {
        sessionStorage.removeItem('nft-typefilter');
        sessionStorage.removeItem('nft-starfilter');
        sessionStorage.removeItem('nft-elementfilter');
      }

      this.typeFilter = '';
      this.starFilter = '';
      this.elementFilter = '';
      this.priceSort = '';

      this.$emit('nft-filters-changed');
    },

    toggleFavorite(e: Event, type: string, id: number) {
      e.preventDefault();
      if (this.favorites[type] && this.favorites[type][id]) {
        this.$delete(this.favorites[type], id);
      } else {
        if(!this.favorites[type]) {
          this.$set(this.favorites, type, {});
        }
        this.$set(this.favorites[type], id, true);
      }

      localStorage.setItem('favorite-nfts', this.getFavoritesString(this.favorites));

      Events.$emit('nft:newFavorite', { type, id });
    },

    onNftClick(type: string, id: number) {
      this.setCurrentNft({ type, id });
      this.$emit('choose-nft', `${type}.${id}`);
    },

    getFavoritesString(favorites: Record<string, Record<number, boolean>>): string {
      return JSON.stringify(favorites);
    },

    checkStorageFavorite() {
      const favoritesFromStorage = localStorage.getItem('favorite-nfts');
      if (favoritesFromStorage) {
        this.favorites = JSON.parse(favoritesFromStorage);
      }
    },

    isFavorite(type: string, id: number): boolean {
      return this.favorites && this.favorites[type] && this.favorites[type][id];
    },

    async buyItem(item: nftItem) {
      if(item.type === 'shield'){
        console.log('buying shield');
        await this.purchaseShield();
      }

      if (item.type === 'SecretBox') {
        console.log('Buying secret box');
        if (item.id === 0) { //Common Box
          await this.purchaseCommonSecretBox();
        }
        if (item.id === 1) { // Rare Box
          await this.purchaseRareSecretBox();
        }
      }

      if(item.type === 'CharacterRenameTag'){
        await this.purchaseRenameTag();
      }
      if(item.type === 'CharacterRenameTagDeal'){
        await this.purchaseRenameTagDeal();
      }

      if(item.type === 'WeaponRenameTag'){
        await this.purchaseWeaponRenameTag();
      }
      if(item.type === 'WeaponRenameTagDeal'){
        await this.purchaseWeaponRenameTagDeal();
      }

      if(item.type === 'CharacterFireTraitChange'){
        await this.purchaseCharacterFireTraitChange();
      }
      if(item.type === 'CharacterEarthTraitChange'){
        await this.purchaseCharacterEarthTraitChange();
      }
      if(item.type === 'CharacterWaterTraitChange'){
        await this.purchaseCharacterWaterTraitChange();
      }
      if(item.type === 'CharacterLightningTraitChange'){
        await this.purchaseCharacterLightningTraitChange();
      }
    },
    itemDescriptionHtml(item: SkillShopListing): string {
      return item.name + '<br>' + item.description;
    }
  },

  mounted() {
    this.checkStorageFavorite();

    if(!this.showGivenNftIdTypes) {
      this.fetchShields(this.ownedShieldIds);
    }

    Events.$on('nft:newFavorite', () => this.checkStorageFavorite());

    if(this.isMarket) {
      this.typeFilter = sessionStorage.getItem('market-nft-typefilter') || '';
      this.starFilter = sessionStorage.getItem('market-nft-starfilter') || '';
      this.elementFilter = sessionStorage.getItem('market-nft-elementfilter') || '';
      this.priceSort = sessionStorage.getItem('market-nft-price-order') || '';
    } else {
      this.typeFilter = sessionStorage.getItem('nft-typefilter') || '';
      this.starFilter = sessionStorage.getItem('nft-starfilter') || '';
      this.elementFilter = sessionStorage.getItem('nft-elementfilter') || '';
    }
  }
});
