





































import Vue from "vue";
import Bignumber from "bignumber.js";
import { Accessors } from "vue/types/options";
import { mapActions, mapState, mapGetters } from "vuex";
import { toBN, fromWeiEther } from "../../utils/common";
import { IState } from "@/interfaces";
import { formatDurationFromSeconds } from "@/utils/date-time";

type StoreMappedState = Pick<IState,
| "skillRewards"
| "skillBalance"
| "inGameOnlyFunds"
| "waxBridgeWithdrawableBnb"
| "waxBridgeTimeUntilLimitExpires">;

interface StoreMappedGetters {
  getExchangeUrl: string;
  availableBNB: string;
}

interface StoreMappedActions {
  addMoreSkill(skillToAdd: string): Promise<void>;
  withdrawBnbFromWaxBridge(): Promise<void>;
}

export default Vue.extend({
  computed: {
    ...(mapState([
      "skillRewards",
      "skillBalance",
      "inGameOnlyFunds",
      "waxBridgeWithdrawableBnb",
      "waxBridgeTimeUntilLimitExpires",
    ]) as Accessors<StoreMappedState>),
    ...(mapGetters({
      availableBNB: "waxBridgeAmountOfBnbThatCanBeWithdrawnDuringPeriod",
      getExchangeUrl: "getExchangeUrl",
    }) as Accessors<StoreMappedGetters>),

    formattedTotalSkillBalance() {
      const xBladeBalance = fromWeiEther(
        Bignumber.sum(
          toBN(this.skillBalance),
          toBN(this.inGameOnlyFunds),
          toBN(this.skillRewards)
        )
      );
      const { format } = new Intl.NumberFormat("en-EN");
      return `${format(
        toBN(xBladeBalance).toNumber()
      )}`;
    },

    formattedSkillBalance(): string {
      const skillBalance = fromWeiEther(this.skillBalance);
      return `${toBN(skillBalance).toFixed(4)} xBlade`;
    },

    hasBnbAvailableToWithdraw(): boolean {
      return toBN(this.waxBridgeWithdrawableBnb).gt(0);
    },

    canWithdrawBnb(): boolean {
      return toBN(this.availableBNB).gt(0);
    },

    formattedBnbThatCanBeWithdrawn(): string {
      return this.formatBnb(this.availableBNB);
    },

    formattedTotalAvailableBnb(): string {
      return this.formatBnb(this.waxBridgeWithdrawableBnb);
    },

    durationUntilLimitPeriodOver(): string {
      return formatDurationFromSeconds(this.waxBridgeTimeUntilLimitExpires);
    },

    bnbClaimTooltip(): string {
      if (!this.canWithdrawBnb) {
        return `
          You have reached your limit for withdrawing BNB from the portal for this period,
          please wait about ${this.durationUntilLimitPeriodOver}
          (${this.formattedTotalAvailableBnb} left)
        `;
      }

      return `${this.formattedBnbThatCanBeWithdrawn} of ${this.formattedTotalAvailableBnb} withdrawable from the portal`;
    },
    formattedInGameOnlyFunds(): string {
      const skillBalance = fromWeiEther(this.inGameOnlyFunds);
      return `${toBN(skillBalance).toFixed(4)} xBlade`;
    },
    totalSkillTooltipHtml() {
      const inGameOnlyFundsBalance = fromWeiEther(this.inGameOnlyFunds);
      const skillRewards = fromWeiEther(this.skillRewards);
      const skillBalance = fromWeiEther(this.skillBalance);

      let html = toBN(skillBalance).toFixed(4) + "xBlade";

      if (parseFloat(skillRewards) !== 0) {
        html +=
          "<br>+ WITHDRAWABLE " + toBN(skillRewards).toFixed(4) + "xBlade";
      }

      if (parseFloat(inGameOnlyFundsBalance) !== 0) {
        html +=
          "<br>+ IN GAME ONLY " +
          toBN(inGameOnlyFundsBalance).toFixed(4) +
          "xBlade";
      }

      return html;
    },
    hasInGameSkill(): boolean {
      const inGameOnlyFundsBalance = fromWeiEther(this.inGameOnlyFunds);
      return parseFloat(inGameOnlyFundsBalance) !== 0;
    },
  },

  methods: {
    ...(mapActions([
      "addMoreSkill",
      "withdrawBnbFromWaxBridge",
    ]) as StoreMappedActions),

    formatBnb(bnb: string): string {
      const amount = fromWeiEther(bnb);
      return `${toBN(amount).toFixed(4)} BNB`;
    },

    onBuySkill() {
      window.open(this.getExchangeUrl, "_blank");
    },

    async onWithdrawBNB() {
      if (!this.canWithdrawBnb) return;

      await this.withdrawBnbFromWaxBridge();
    },
  },

  components: {},
});
