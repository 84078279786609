






export default {
  props: ['text', 'disabled'],
};
